import { Injectable } from "@angular/core"

import { FeatureDto, CreateFeatureDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"


@Injectable()
export class FeatureService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<FeatureDto>>('/v1/admin/features', pagingDto)
  }

  async update(id: string, updateFeatureOnboardDto: CreateFeatureDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/features/${id}`, updateFeatureOnboardDto)
  }

  async create(createFeatureOnboardDto: CreateFeatureDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/features`, createFeatureOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/features/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/features/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/features/${id}/disable`)
  }
}