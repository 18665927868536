import { Injectable } from "@angular/core"

import { AnnouncementDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class AnnouncementService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<AnnouncementDto>>('/v1/admin/announcements', pagingDto)
  }

  async update(id: string, announcementDto: AnnouncementDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/announcements/${id}`, announcementDto)
  }

  async create(announcementDto: AnnouncementDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/announcements`, announcementDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/announcements/${id}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/v1/admin/announcements/${id}/published`)
  }

}