import * as _ from 'lodash'

import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'

export class CorporateBankSocialDto extends BaseDto {
  link: string
  title: string
  icon: FileDto
  iconId: string
  enabled: boolean

  static create(social?: CorporateBankSocialDto) {
    const result = new CorporateBankSocialDto()

    result.id = social?.id ?? null,
    result.title = social?.title ?? null,
    result.link = social?.link ?? null
    result.icon =  social?.icon ?? null
    result.iconId =  social?.icon?.id ?? null
    result.enabled = social?.enabled ?? true
    
    return result
  }
}