import { Injectable } from "@angular/core"

import { LocationDto, CreateLocationDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"


@Injectable()
export class LocationService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<LocationDto>>('/v1/admin/locations', pagingDto)
  }

  async update(id: string, updateLocationOnboardDto: CreateLocationDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/locations/${id}`, updateLocationOnboardDto)
  }

  async create(createLocationOnboardDto: CreateLocationDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/locations`, createLocationOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/locations/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/locations/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/locations/${id}/disable`)
  }
}