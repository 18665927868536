import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CardInformationDto, PageResultDto } from '../dto'
import { PagingDto } from '../dto/paging.dto'

@Injectable()
export class CardInformationService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<CardInformationDto>>('/v1/admin/card-information', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<CardInformationDto>(`/v1/admin/card-information/${pageId}`)
  }

  async update(pageId: string, cardInformationDto: CardInformationDto) {
    return await this.httpService.put<CardInformationDto>(`/v1/admin/card-information/${pageId}`, cardInformationDto)
  }
}
