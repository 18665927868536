import { NgModule, ModuleWithProviders } from '@angular/core'
import { RouterModule } from '@angular/router'

import { NbLayoutModule, NbSidebarModule, NbIconModule, NbSelectModule, NbSearchModule, NbActionsModule, NbUserModule, NbContextMenuModule, NbAlertModule, NbMenuItem } from '@nebular/theme'
import { NbSecurityModule } from '@nebular/security'
import { MenuModule } from '../menu'

import { ThemeModule } from '../../@theme/theme.module'
import { PlaceholderModule } from './../placeholder'

import { HeaderComponent } from './header'
import { LayoutComponent } from './layout.component'
import { MMSLayoutService } from './layout.service'
import { LAYOUT_MODULE_CONFIG } from './layout.constants'

export interface LayoutModuleConfig {
  menu: NbMenuItem[],
  root: string,
}

@NgModule({
  providers: [
    MMSLayoutService
  ],
  declarations: [
    HeaderComponent,
    LayoutComponent
  ],
  imports: [
    ThemeModule,
    RouterModule,
    NbLayoutModule,
    NbSidebarModule,
    NbIconModule,
    NbSelectModule,
    NbSearchModule,
    NbActionsModule,
    NbUserModule,
    NbContextMenuModule,
    NbSecurityModule,
    NbAlertModule,
    PlaceholderModule,
    MenuModule.forRoot(),
  ],
  exports: [
    LayoutComponent
  ]
})
export class LayoutModule {
  static forChild(config: LayoutModuleConfig): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        {
          provide: LAYOUT_MODULE_CONFIG, 
          useValue: config
        }
      ]
    };
  }
}
