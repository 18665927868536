import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FeaturedPropertyDto, PageResultDto, PropertyDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { PropertyForm } from '../../+admin/+property/edit-property/edit-property.utils'
import { PropertyFeatureDto } from '../dto/property/property-feature.dto'


@Injectable()
export class PropertyService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createProperty: PropertyForm) {
    return await this.httpService.post<PropertyDto>(`/v1/admin/properties`, createProperty)
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PropertyDto>>('/v1/admin/properties', pagingDto)
  }

  async findOne(propertyId: string) {
    return this.httpService.get<PropertyDto>(`/v1/admin/properties/${propertyId}`)
  }

  async update(propertyId: string, updateProperty: PropertyForm) {
    return await this.httpService.put<boolean>(`/v1/admin/properties/${propertyId}`, updateProperty)
  }

  async destroy(propertyId: string) {
    return this.httpService.delete<PropertyDto>(`/v1/admin/properties/${propertyId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/v1/admin/properties/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/v1/admin/properties/${id}/enable`)
    }

    return await this.httpService.put(`/v1/admin/properties/${id}/disable`)
  }

  async sortOrderProperty(propertyIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/properties/reorder`, { propertyIds })
  }

  ///////////////////////////////////////
  // Feature
  ///////////////////////////////////////

  async updateFeature(corporateBankId: string, featureId: string, propertyFeatureDto: PropertyFeatureDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/properties/${corporateBankId}/feature/${featureId}`, propertyFeatureDto)
  }

  async createFeature(corporateBankId: string, propertyFeatureDto: PropertyFeatureDto) {
    return await this.httpService.post<PropertyFeatureDto>(`/v1/admin/properties/${corporateBankId}/feature`, propertyFeatureDto)
  }

  async deleteFeature(corporateBankId: string, featureId: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/properties/${corporateBankId}/feature/${featureId}`)
  }

  async sortOrderFeature(corporateBankId: string, featureIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/properties/${corporateBankId}/feature/reorder`, { featureIds })
  }
}
