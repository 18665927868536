import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class FeedbackTypeDto extends BaseDto {
  title: TranslationDto = TranslationDto.create()
  description: TranslationDto = TranslationDto.create()
  icon?: FileDto
  fileId: string
  enabled?: boolean
  telegramChatId: string
  parent: FeedbackTypeDto

  static create(feedbackType?: FeedbackTypeDto) {
    const result = new FeedbackTypeDto()

    result.id = feedbackType.id
    result.title = feedbackType.title
    result.description = feedbackType.description
    result.telegramChatId = feedbackType.telegramChatId ?? null,
    result.icon = feedbackType?.icon ?? null
    result.fileId = feedbackType?.icon?.id ?? null
    result.enabled =  feedbackType?.enabled ?? true
    result.parent = feedbackType.parent ?? null
    
    return result
  }
}