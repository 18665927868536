import { Injectable } from "@angular/core"

import { BannerDto, CreateBannerDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class BannerService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<BannerDto[]>('/v1/admin/banners')
  }

  async update(id: string, updateBannerOnboardDto: CreateBannerDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/banners/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerOnboardDto: CreateBannerDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/banners`, createBannerOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/banners/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/banners/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/banners/${id}/disable`)
  }

  async sortOrder(bannerIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/banners/reorder`, { bannerIds })
  }
}