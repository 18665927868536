import { BaseDto } from './base.dto'
import { FileDto } from './file.dto'

export class UserDto extends BaseDto {
  fullName: string
  email: string
  phoneNumber: string
  customerId: string
  secondaryPhoneNumber: string
  enabled: boolean
  dob: string
  idCard: string
  address: string
  occupation: string
  facebookLink: string
  instagramLink: string
  twitterLink: string
  roleNames: string[] = []
  photo: FileDto

  static create() {
    const user = new UserDto()
    
    user.fullName = ''
    user.email = ''
    user.roleNames = []

    return user
  }
}

export class CreateUserDto {
  fullName: string
  email: string
  password: string
  roleName: RoleNames
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export class UpdateUserDto {
  fullName: string
  email: string
  roleName: RoleNames
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export enum RoleNameRoute {
  PH_USER = 'ph-user',
  E_PRIVILEGE = 'e-privilege',
  GUEST = 'guest',
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  PH_USER = 'PH_USER',
  E_PRIVILEGE = 'E_PRIVILEGE',
  GUEST = 'GUEST',
  ADMIN = 'ADMIN',
  TESTER = 'TESTER',
  RESEARCH_DEVELOPMENT = 'RESEARCH_DEVELOPMENT',
  MARKETING = 'MARKETING',
  SALE = 'SALE',
  SERVICE = 'SERVICE',
  CALL_CENTER = 'CALL_CENTER',
  FACILITY_MANAGEMENT = 'FACILITY_MANAGEMENT',
  MKT_INVESTMENT = 'MKT_INVESTMENT',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT'
}

export enum AdminRoleNames {
 
}