import { Injectable } from '@angular/core'

import { Store, StoreConfig } from '@datorama/akita'
import { Query } from '@datorama/akita'
import { ProfileDto } from '@api/dto'

export interface AuthState {
  sessionToken: string
  user: ProfileDto,
  isAdmin: boolean
}

export function createInitialState(): AuthState {
  return {
    sessionToken: '',
    user: null,
    isAdmin: false
  }
}

@Injectable()
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState())
  }
}

@Injectable()
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select(state => !!state.sessionToken)
  authUser$ = this.select(state => state.user)
  isAdmin$ = this.select(state => state.isAdmin)
  
  constructor(protected storeValue: AuthStore) {
    super(storeValue);
  }
}
