import { BaseDto } from "../common/base.dto"

export class SettingDto extends BaseDto {
  key: string
  type: string
  value: string | number | boolean
  
  static create() {
    return new SettingDto()
  }
}

export enum SettingKey {
  AppointmentDetailContent = 'Appointment Detail Content',
  SunrisePolicyTitle = 'Sunrise Policy Title',
  SunrisePolicyContent = 'Sunrise Policy Content',
  DefaultRequestTypeLogo = 'Default Request Type Logo'
}
