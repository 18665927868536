import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class LocationDto extends BaseDto {
  location: TranslationDto
  enabled?: boolean

  static create(location?: LocationDto) {
    const result = new LocationDto()

    result.id = location?.id ?? null,
    result.location = location?.location ?? TranslationDto.create()
    result.enabled =  location?.enabled ?? true
    
    return result
  }
}

export class CreateLocationDto {
  location?: TranslationDto
}