import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'


export class DashboardDto extends BaseDto {
  numberOfPatientToday: number
  numberOfPatientWeek: number
  numberOfPatientCustom: number

  numberOfInvoiceToday: number
  numberOfInvoiceWeek: number
  numberOfInvoiceCustom: number

  numberOfAppointmentToday: number
  numberOfAppointmentWeek: number
  numberOfAppointmentCustom: number

  numberOfFeedbackToday: number
  numberOfFeedbackWeek: number
  numberOfFeedbackCustom: number

  numberOfCompletedAppointmentToday: number
  numberOfCompletedAppointmentWeek: number
  numberOfCompletedAppointmentCustom: number

  numberOfCancelledAppointmentToday: number
  numberOfCancelledAppointmentWeek: number
  numberOfCancelledAppointmentCustom: number
  static create() {
    return _.assign(new DashboardDto())
  }
}