import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class EmergencyCategoryDto extends BaseDto {
  icon?: FileDto
  title: TranslationDto
  enabled?: boolean
  iconId: string

  static create(category?: EmergencyCategoryDto) {
    const result = new EmergencyCategoryDto()

    result.id = category?.id ?? null,
    result.title = category?.title ?? TranslationDto.create()
    result.icon = category?.icon ?? null
    result.iconId = category?.icon?.id ?? null
    result.enabled =  category?.enabled ?? true
    
    return result
  }
}

export class CreateEmergencyCategoryDto {
  iconId?: string
  title?: TranslationDto
}