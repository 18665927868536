import { Injectable } from "@angular/core"

import { DateFilterDto, FeedbackDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { FindFeedbackDto } from "../dto/feedback/feedback.dto"
import { ExportExcelResultDto } from "@api/dto/common/export-excel-result.dto"

@Injectable()
export class FeedbackService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findFeedbackDto: FindFeedbackDto) {
    return await this.httpService.get<PageResultDto<FeedbackDto>>('/v1/admin/feedbacks', findFeedbackDto)
  }
  
  async findOne(id: string) {
    return await this.httpService.get<FeedbackDto>(`/v1/admin/feedbacks/${id}`)
  }

  async exportExcel(dateFilterDto: DateFilterDto, status?: string) {
    let params = { ...dateFilterDto };
    if (status) {
      params['status'] = status;
    }

    return this.httpService.get<ExportExcelResultDto>(`/v1/admin/feedbacks/export`, params)
  }
}