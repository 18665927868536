import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'

export class BookingVisitTimeDto extends BaseDto {
  time: string
  enabled?: boolean

  static create(slot?: BookingVisitTimeDto) {
    let result = new BookingVisitTimeDto()
    result.id = slot?.id ?? null
    result.time = slot?.time ?? null
    result.enabled = slot?.enabled ?? true
    return result
  }
}