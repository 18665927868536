import * as _ from 'lodash'

import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { BaseDto } from '../base.dto'
import { ProjectDto } from '../project/project.dto'
import { PropertyTypeDto } from '../property-type/property-type.dto'
import { PropertyFeatureDto } from './property-feature.dto'
import { UserDto } from '../user.dto'

export class PropertyDto extends BaseDto {
  name: TranslationDto = TranslationDto.create()
  description: TranslationDto = TranslationDto.create()
  thumbnail: FileDto
  thumbnailId: string
  galleries: FileDto[]
  youTubeUrl: string
  view360Url: string
  propertyTypeId: string
  shareUrl: string
  projects: ProjectDto[]
  imageIds: string[]
  projectIds: string[]
  propertyType: PropertyTypeDto
  features: PropertyFeatureDto[]
  featuresIds: string[]
  maxPrice: number
  minPrice: number
  enabled: boolean
  updatedBy: UserDto = UserDto.create()

  static create() {
    const page = new PropertyDto()
    return page
  }
}

export class FeaturedPropertyDto {
  projectId: string
}
