import { FileDto } from '../common/file.dto'
import * as _ from 'lodash'

export class ProfileDto {
  fullName: string
  email: string
  photoId: string
  roleName: string
  phoneNumber: string
  photo: FileDto
  password: string
  customerId: string
  secondaryPhoneNumber: string
  dob: string
  idCard: string
  address: string
  occupation: string
  facebookLink: string
  instagramLink: string
  telegramLink: string
  roleNames: string[]
  id: string

  static createForm({ id, fullName, email, roleNames, photo }: ProfileDto) {
    return _.assign<ProfileDto>({
      id,
      fullName,
      email,
      photo,
      roleNames
    })
  }

}

import { assign } from 'lodash'

export class UpdateProfileDto {
  fullName: string

  static create({fullName}: ProfileDto ) {
    return assign<UpdateProfileDto>({
      fullName
    })
  }
}
