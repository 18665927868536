import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'

export class PriceRangeDto extends BaseDto {
  minPrice: number
  maxPrice: number
  enabled?: boolean

  static create(priceRange?: PriceRangeDto) {
    const result = new PriceRangeDto()
    
    result.id = priceRange?.id ?? null
    result.minPrice = priceRange?.minPrice ?? null
    result.maxPrice = priceRange?.maxPrice ?? null
    result.enabled =  priceRange?.enabled ?? true
    
    return result
  }
}