import { Injectable } from "@angular/core"

import { CorporateBankDto, CorporateBankSocialDto } from "../dto"
import { HttpService } from "./http.service"
import { CorporateBankBannerDto } from "../dto/corporate-bank/corporate-bank-banner"

@Injectable()
export class CorporateBankService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<CorporateBankDto[]>('/v1/admin/corporate-banks')
  }

  async findOne(id: string) {
    return await this.httpService.get<CorporateBankDto>(`/v1/admin/corporate-banks/${id}`)
  }

  async update(id: string, corporateBankDto: CorporateBankDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/${id}`, corporateBankDto)
  }

  async create(corporateBankDto: CorporateBankDto) {
    return await this.httpService.post<CorporateBankDto>(`/v1/admin/corporate-banks`, corporateBankDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/corporate-banks/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/${id}/disable`)
  }

  async sortOrder(corporateBankIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/reorder`, { corporateBankIds })
  }

  ///////////////////////////////////////
  // Banner
  ////////////////////////////////////////

  async findBanner() {
    return await this.httpService.get<CorporateBankBannerDto[]>(`/v1/admin/corporate-banks/banner`)
  }

  async createBanner(corporateBankBannerDto: CorporateBankBannerDto) {
    return await this.httpService.post<CorporateBankDto>(`/v1/admin/corporate-banks/banner`, corporateBankBannerDto)
  }

  async deleteBanner(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/corporate-banks/banner/${id}`)
  }

  async setEnabledBanner(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/banner/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/banner/${id}/disable`)
  }

  async sortOrderBanner(corporateBankBannerIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/banner/reorder`, { corporateBankBannerIds })
  }

  async updateBanner(id: string, corporateBankBannerDto: CorporateBankBannerDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/banner/${id}`, corporateBankBannerDto)
  }

  ///////////////////////////////////////
  // Social Corporate Bank
  ///////////////////////////////////////

  async updateSocial(corporateBankId: string, socialId: string, corporateBankSocialDto: CorporateBankSocialDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/${corporateBankId}/social/${socialId}`, corporateBankSocialDto)
  }

  async createSocial(corporateBankId: string, corporateBankSocialDto: CorporateBankSocialDto) {
    return await this.httpService.post<CorporateBankDto>(`/v1/admin/corporate-banks/${corporateBankId}/social`, corporateBankSocialDto)
  }

  async deleteSocial(corporateBankId: string, socialId: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/corporate-banks/${corporateBankId}/social/${socialId}`)
  }

  async setEnabledSocial(corporateBankId: string, socialId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/${corporateBankId}/social/${socialId}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/corporate-banks/${corporateBankId}/social/${socialId}/disable`)
  }

  async sortOrderSocial(corporateBankId: string, socialIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/corporate-banks/${corporateBankId}/social/reorder`, { socialIds })
  }
}