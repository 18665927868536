import { Injectable } from "@angular/core"

import { StoreDto, PageResultDto, DateFilterDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"
import { FindStoreRedemption, StoreRedemptionDto } from "../dto/store/store-redemption.dto"
import { ExportExcelResultDto } from "@api/dto/common/export-excel-result.dto"

@Injectable()
export class StoreService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<StoreDto>>('/v1/admin/stores', pagingDto)
  }

  async findRedemption(pagingDto: FindStoreRedemption) {
    return await this.httpService.get<PageResultDto<StoreRedemptionDto>>('/v1/admin/stores/redemption', pagingDto)
  }

  async exportRedemption(dateFilterDto: DateFilterDto) {
    return await this.httpService.get<ExportExcelResultDto>('/v1/admin/stores/redemption/export', dateFilterDto )
  }

  async findOne(id: string) {
    return await this.httpService.get<StoreDto>(`/v1/admin/stores/${id}`)
  }

  async update(id: string, storeDto: StoreDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/stores/${id}`, storeDto)
  }

  async create(storeDto: StoreDto) {
    return await this.httpService.post<StoreDto>(`/v1/admin/stores`, storeDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/stores/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/stores/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/stores/${id}/disable`)
  }
}