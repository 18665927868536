import { Injectable } from "@angular/core"

import { CategoryDto, CreateCategoryDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class CategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<CategoryDto[]>('/v1/admin/categories')
  }

  async update(id: string, updateCategoryOnboardDto: CreateCategoryDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/categories/${id}`, updateCategoryOnboardDto)
  }

  async create(createCategoryOnboardDto: CreateCategoryDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/categories`, createCategoryOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/categories/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/categories/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/categories/${id}/disable`)
  }

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/categories/reorder`, { categoryIds })
  }
}