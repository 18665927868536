import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'

import { Observable } from 'rxjs'

import { NbSidebarService } from '@nebular/theme'

import { ProfileDto } from '@api/dto'
import { AuthQuery } from '@api/store/auth'

import { LayoutService } from '../../../@core/utils'
import { MMSLayoutService } from '../layout.service'

@Component({
  selector: 'mms-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public userPictureOnly: boolean = false
  public isAdmin: Observable<boolean>
  public authUser: Observable<ProfileDto>
  public mode: string
  public items = [
    {
      title: 'Profile',
      icon: 'person-outline',
      link: [],
      tag: 'profile'
    },
    {
      title: 'Logout',
      icon: 'unlock-outline',
      link: [],
      tag: 'logout'
    }
  ]

  constructor(
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private mmsLayoutService: MMSLayoutService,
    private location: Location,
    private authQuery: AuthQuery) {
  }

  public async ngOnInit() {
    this.isAdmin = this.authQuery.isAdmin$

    this.authUser = this.authQuery.authUser$

    this.mmsLayoutService.onModeChange.subscribe(mode => {
      this.mode = mode
    })
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar')
    this.layoutService.changeLayoutSize()

    return false
  }

  public onBack() {
    this.location.back()
  }
}
