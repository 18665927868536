import { Injectable } from "@angular/core"

import { EmergencyCategoryDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class EmergencyCategoryService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<EmergencyCategoryDto[]>('/v1/admin/emergency-categories')
  }

  async update(id: string, emergencyCategoryOnboardDto: EmergencyCategoryDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/emergency-categories/${id}`, emergencyCategoryOnboardDto)
  }

  async create(emergencyCategoryDto: EmergencyCategoryDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/emergency-categories`, emergencyCategoryDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/emergency-categories/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/emergency-categories/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/emergency-categories/${id}/disable`)
  }

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/emergency-categories/reorder`, { categoryIds })
  }
}