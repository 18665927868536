import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { PagingDto } from '../common/paging.dto'
import { TranslationDto } from '../common/translation.dto'


export class AwardDto extends BaseDto {
  image?: FileDto
  imageId?: string
  title: TranslationDto
  year: number
  description: TranslationDto
  enabled?: boolean

  static create(award?: AwardDto) {
    const result = new AwardDto()
    result.id = award?.id ?? null
    result.title = award?.title ?? TranslationDto.create()
    result.description = award?.description ?? TranslationDto.create()
    result.image = award?.image ?? null
    result.imageId = award?.image?.id ?? null
    result.year = award?.year ?? null
    
    return result
  }
}