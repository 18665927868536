export class TranslationDto<T = any> {
  en: T
  km: T

  public static create(en?: any, km? : any) {
    const translation = new TranslationDto()

    translation.en = en || ''
    translation.km = km || ''

    return translation
  }
}