import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreatePushNotificationDto, FindPushNotificationDto, PageResultDto, PushNotificationDto } from '../dto'

@Injectable()
export class PushNotificationService {
  constructor(
    private httpService: HttpService) {
  }

  async find({ limit, offset }: FindPushNotificationDto) {
    return await this.httpService.get<PageResultDto<PushNotificationDto>>(`/v1/admin/push-notification`, { limit, offset })
  }

  async create({ title, message, creator, link }: CreatePushNotificationDto) {
    return await this.httpService.post<boolean>('/v1/admin/push-notification', { title, message, creator, link })
  }
}
