import { Injectable } from "@angular/core"

import { HouseTypeDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"


@Injectable()
export class HouseTypeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<HouseTypeDto>>('/v1/admin/house-types', { limit, offset })
  }

  async setImage(id: string, { fileId }: HouseTypeDto) {
    return await this.httpService.patch<Boolean>(`/v1/admin/house-types/${id}/image`, { fileId })
  }
}