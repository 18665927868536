import { assign } from 'lodash'

import _ from 'lodash'
import { BaseDto } from '../base.dto'
import { HouseTypeDto } from '../house-type/house-type.dto'
import { UserDto } from './user.dto'

export class UserPropertyDto extends BaseDto {
  houseCode: string
  projectName: string
  location: string
  paymentPlan: string
  customerId: string
  ownerName: string
  spaDate: string
  propertyPrice: string
  outstandingBalance: string
  paymentType: string
  constructionProgress: number
  houseAddress: string
  houseTypeCode: string
  paymentStatus: string
  hardTitle: boolean
  projectCode: string
  propertyStatus: string
  ownershipType: string
  coApplicantName: string
  houseType: HouseTypeDto
  owner: UserDto

  static create() {
    return new UserPropertyDto()
  }
}
