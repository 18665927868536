import { Injectable } from "@angular/core"

import { AllowPhoneNumberDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class AllowPhoneNumberService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<AllowPhoneNumberDto>>('/v1/admin/allow-phone-numbers', pagingDto)
  }

  async update(id: string, allowPhoneNumberDto: AllowPhoneNumberDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/allow-phone-numbers/${id}`, allowPhoneNumberDto)
  }

  async create(allowPhoneNumberDto: AllowPhoneNumberDto) {
    return await this.httpService.post<AllowPhoneNumberDto>(`/v1/admin/allow-phone-numbers`, allowPhoneNumberDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/allow-phone-numbers/${id}`)
  }
}