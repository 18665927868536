import * as _ from 'lodash'

import { BaseDto } from '../common/base.dto'
import { UserPropertyDto } from '../user/user-property.dto'
import { RepairTypeDto } from '../repair-type/repair-type.dto'
import { PhFileDto } from '../common/ph-file.dto'
import { FeedbackTypeDto } from '../feedback-type/feedback-type.dto'
import { PagingDto } from '../paging.dto'

export class FeedbackDto extends BaseDto {
  requestNo: string
  requestDate: string
  customerId: string
  phoneNumber: string
  location: string
  video: PhFileDto
  description: string
  voice: PhFileDto
  status: string
  feedbackType: FeedbackTypeDto
  images: PhFileDto[]

  static create() {
    const result = new FeedbackDto()
    return result
  }
}

export class FeedbackRatingDto extends BaseDto {
  rate: number
  description: string

  static create() {
    return new FeedbackRatingDto()
  }
}

export class FindFeedbackDto extends PagingDto {
  public status: string
  public searchText: string
}
