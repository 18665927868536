import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { TelegrafDto } from '../dto'

@Injectable()
export class TelegrafService {
  constructor(
    private httpService: HttpService) {
  }

  async find() {
    return await this.httpService.get<TelegrafDto[]>('/v1/admin/telegraf')
  }

  async update(telegrafId: string, telegrafDto: TelegrafDto) {
    return await this.httpService.put<TelegrafDto>(`/v1/admin/telegraf/${telegrafId}`, telegrafDto)
  }
}
