import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { PagingDto } from '../common/paging.dto'

export class BookletDto extends BaseDto {
  title?: TranslationDto
  enabled?: boolean
  type: string
  externalLink?: string
  thumbnail?: FileDto

  static create(booklet?: BookletDto) {
    const result = new BookletDto()

    result.id = booklet?.id ?? null,
    result.title = booklet?.title ?? TranslationDto.create()
    result.thumbnail = booklet?.thumbnail ?? null
    result.type = booklet?.type ?? null
    result.externalLink = booklet?.externalLink ?? null
    result.enabled =  booklet?.enabled ?? true
    
    return result
  }
}

export class CreateBookletDto {
  title: TranslationDto
  externalLink: string
  type: string
  enabled?: boolean
  thumbnailId?: string
}

export class FindBookletDto extends PagingDto {
  type: string
}