import { Injectable } from "@angular/core"

import { AmenityDto, CreateAmenityDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"


@Injectable()
export class AmenityService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<AmenityDto>>('/v1/admin/amenities', pagingDto)
  }

  async update(id: string, updateAmenityOnboardDto: CreateAmenityDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/amenities/${id}`, updateAmenityOnboardDto)
  }

  async create(createAmenityOnboardDto: CreateAmenityDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/amenities`, createAmenityOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/amenities/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/amenities/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/amenities/${id}/disable`)
  }
}