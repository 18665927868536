import { Injectable } from "@angular/core"

import { BookingVisitTimeDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"
import { BookingVisitDto } from "../dto/booking-visit/booking-visit.dto"


@Injectable()
export class BookingVisitService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<BookingVisitDto>>('/v1/admin/booking-visit', pagingDto)
  }

  async findTime(pagingDto: PagingDto) {
    return await this.httpService.get<BookingVisitTimeDto[]>('/v1/admin/booking-visit/time', pagingDto)
  }

  async update(id: string, updateBannerOnboardDto: BookingVisitTimeDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/booking-visit/time/${id}`, updateBannerOnboardDto)
  }

  async create(createBannerOnboardDto: BookingVisitTimeDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/booking-visit/time`, createBannerOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/booking-visit/time/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/booking-visit/time/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/booking-visit/time/${id}/disable`)
  }
}