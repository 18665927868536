import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"

import { FeaturedMerchantDto, MerchantDto, PageResultDto, TranslationDto } from "../dto"
import { PagingDto } from "../dto/paging.dto"
import { DiscountMerchantDto } from "@api/dto/merchant/merchant.dto"


@Injectable()
export class MerchantService {
  constructor(
    private httpService: HttpService
  ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<MerchantDto>>('/v1/admin/merchants', pagingDto)
  }

  async findOne(merchantId: string) {
    return await this.httpService.get<MerchantDto>(`/v1/admin/merchants/${merchantId}`)
  }

  async deleteDiscountMerchant(discountMerchantId: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/merchants/discount/${discountMerchantId}`)
  }

  async findDiscountMerchant(merchantId: string, pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<DiscountMerchantDto>>(`/v1/admin/merchants/discount/${merchantId}`, pagingDto)
  }

  async updateDiscountMerchant(discount: TranslationDto, merchantId: string, applyAll: boolean, cardInformationId?: string) {
    return await this.httpService.put<Boolean>(`/v1/admin/merchants/discount`, { discount, applyAll, cardInformationId, merchantId })
  }

  async createDiscountMerchant(discount: TranslationDto, merchantId: string, cardInformationId: string) {
      return await this.httpService.post<Boolean>(`/v1/admin/merchants/discount`, { discount, cardInformationId, merchantId })
  }

  async update(id: string, merchantDto: MerchantDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/merchants/${id}`, merchantDto)
  }

  async create(merchantDto: MerchantDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/merchants`, merchantDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/merchants/${id}`)
  }

  async createFeature({ merchantId }: FeaturedMerchantDto) {
    return await this.httpService.post<Boolean>('/v1/admin/merchants/featured', { merchantId })
  }

  async findFeature() {
    return await this.httpService.get<MerchantDto[]>('/v1/admin/merchants/featured')
  }

  async sortOrder(merchantIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/merchants/reorder`, { merchantIds })
  }

  async deleteFeature(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/merchants/featured/${id}`)
  }


  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/merchants/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/merchants/${id}/disable`)
  }
}