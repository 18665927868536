import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { BaseDto } from '../base.dto'
import { LocationDto } from '../location/location.dto'
import { FacilityDto } from '../facility/facility.dto'
import { AmenityDto } from '../amenity/amenity.dto'
import { UserDto } from '../user.dto'
export class ProjectDto extends BaseDto {
  name: TranslationDto = TranslationDto.create()
  address: TranslationDto = TranslationDto.create()
  latitude: number
  longitude: number
  thumbnail: FileDto
  thumbnailId: string
  logo: FileDto
  logoId: string
  galleries: FileDto[]
  status: string
  imageIds: string[]
  youTubeUrl: string
  phoneNumber: string
  shareUrl: string
  location: LocationDto
  locationId: string
  facilities: FacilityDto[]
  facilityIds: string[]
  amenities: AmenityDto[]
  amenityIds: string[]
  enabled: boolean
  updatedBy: UserDto = UserDto.create()
  properties: any[]

  static create() {
    const page = new ProjectDto()
    return page
  }
}

export class FeaturedProjectDto {
  projectId: string
}
