import { Injectable } from "@angular/core"

import { FeedbackTypeDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"
import { UpdateTelegrafDto } from "../dto/telegraf/telegraf.dto"


@Injectable()
export class FeedbackTypeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find({ limit, offset }: PagingDto) {
    return await this.httpService.get<PageResultDto<FeedbackTypeDto>>('/v1/admin/feedback-types', { limit, offset })
  }

  async update(id: string, telegrafDto: UpdateTelegrafDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/feedback-types/${id}/telegram`, telegrafDto)
  }

  async setImage(id: string, { fileId }: FeedbackTypeDto) {
    return await this.httpService.patch<Boolean>(`/v1/admin/feedback-types/${id}/image`, { fileId })
  }
}