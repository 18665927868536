import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import * as moment from 'moment'

export class PaymentPromotionDto extends BaseDto {
  title?: TranslationDto
  content?: TranslationDto
  discountType: string
  promotionType: string
  validMonths: number | string
  freeAmounts: number
  expiredAt: Date
  banner: FileDto
  bannerId: string
  enabled: boolean

  static create(paymentPromotion?: PaymentPromotionDto) {
    const result = new PaymentPromotionDto()

    result.id = paymentPromotion?.id ?? null,
    result.title = paymentPromotion?.title ?? TranslationDto.create()
    result.content = paymentPromotion?.content ?? TranslationDto.create()
    result.banner = paymentPromotion?.banner ?? null
    result.bannerId = paymentPromotion?.banner.id ?? null
    result.validMonths = paymentPromotion?.validMonths?.toString() ?? null
    result.discountType = paymentPromotion?.discountType ?? null
    result.promotionType = paymentPromotion?.promotionType ?? null
    result.expiredAt = moment(paymentPromotion?.expiredAt).toDate() ?? moment().toDate()
    result.freeAmounts = paymentPromotion?.freeAmounts ?? null
    result.enabled =  paymentPromotion?.enabled ?? true
    
    return result
  }
}

export class PaymentPromotionAppliedDto extends BaseDto {
  numberOfMonths: number
  freeAmounts: number
  promotionType: string
  discountType: string
  promotionTotal: number
}