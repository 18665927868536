import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'
import { DragulaService } from 'ng2-dragula'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, PageService, SettingService, DashboardService, CategoryService, AmenityService, FacilityService, FeatureService, LocationService, CorporateBankService, PropertyTypeService, ProjectService, PropertyService, PriceRangeService, MerchantService, StoreService, CardInformationService, RepairTypeService, AnnouncementService, DefectService, TelegrafService, AllowPhoneNumberService, EmergencyService, EmergencyCategoryService, FeedbackService, FeedbackTypeService, EventTypeService, EventService, PushNotificationService, BookletService, PaymentService, PaymentPromotionService } from '@api/services'

import { ApiModuleOptions } from './api.module-options'
import { PromotionService } from '../services/promotion.service'
import { BannerService } from '../services/banner.service'
import { MMSLayoutService } from '../../@shared/layout'
import { LogsService } from '../services/logs.service'
import { BookingVisitService } from '../services/booking-visit.service'
import { NgSelectModule } from '@ng-select/ng-select'
import { QRCodeModule } from 'angularx-qrcode'
import { HouseTypeService } from '../services/house-type.service'
import { WindowRef } from '../services/window-ref.service'

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    QRCodeModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    AuthStore,
    PageService,
    SettingService,
    PromotionService,
    BannerService,
    DragulaService,
    FeatureService,
    LocationService,
    CorporateBankService,
    BookingVisitService,
    DashboardService,
    CategoryService,
    PriceRangeService,
    AmenityService,
    PropertyTypeService,
    CardInformationService,
    StoreService,
    MerchantService,
    RepairTypeService,
    PropertyService,
    HouseTypeService,
    ProjectService,
    DefectService,
    AnnouncementService,
    AllowPhoneNumberService,
    EmergencyService,
    EmergencyCategoryService,
    FeedbackService,
    FeedbackTypeService,
    TelegrafService,
    EventTypeService,
    EventService,
    FacilityService,
    MMSLayoutService,
    PushNotificationService,
    PaymentPromotionService,
    BookletService,
    LogsService,
    PaymentService,
    WindowRef,
    AuthStore,
    AuthQuery
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
