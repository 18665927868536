import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'
import { COLUMN_MODE } from './layout.constants'

@Injectable()
export class MMSLayoutService {
  public onModeChange: BehaviorSubject<string>

  constructor() {
    this.onModeChange = new BehaviorSubject(COLUMN_MODE)
  }

  public setMode(mode: string) {
    this.onModeChange.next(mode)
  }
}