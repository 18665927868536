import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'

export class TelegrafDto extends BaseDto {
  chatId: string
  groupType?: string

  static create(category?: TelegrafDto) {
    const result = new TelegrafDto()

    result.id = category?.id ?? null,
    result.chatId = category?.chatId ?? null,
    result.groupType =  category?.groupType ?? null
    
    return result
  }
}

export class UpdateTelegrafDto {
  chatId?: string
}