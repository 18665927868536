import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { PromotionDto } from '../promotion/promotion.dto'

import * as moment from 'moment'
import { UserDto } from '../user.dto'

export class AnnouncementDto extends BaseDto {
  title: TranslationDto
  thumbnail: FileDto
  promotion: PromotionDto
  thumbnailId: string
  promotionId: string
  isPublished: boolean
  expiredAt: Date
  updatedBy: UserDto

  static create(announcement?: AnnouncementDto) {
    const result = new AnnouncementDto()

    result.id = announcement?.id ?? null,
    result.title = announcement?.title ?? TranslationDto.create()
    result.promotion = announcement?.promotion ?? null
    result.promotionId = announcement?.promotion?.id ?? null
    result.thumbnail = announcement?.thumbnail ?? null
    result.thumbnailId = announcement?.thumbnail?.id ?? null
    result.isPublished = announcement?.isPublished ?? null
    result.expiredAt = moment(announcement?.expiredAt).toDate() ?? moment().toDate()
    result.updatedBy = announcement?.updatedBy ?? UserDto.create() 
    
    return result
  }
}