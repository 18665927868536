import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class FeatureDto extends BaseDto {
  logo?: FileDto
  logoId: string
  title: TranslationDto
  unit: TranslationDto
  enabled?: boolean

  static create(category?: FeatureDto) {
    const result = new FeatureDto()

    result.id = category?.id ?? null,
    result.title = category?.title ?? TranslationDto.create()
    result.unit = category?.unit ?? TranslationDto.create()
    result.logo = category?.logo ?? null
    result.enabled =  category?.enabled ?? true
    
    return result
  }
}

export class CreateFeatureDto {
  logoId?: string
  title?: TranslationDto
  unit?: TranslationDto
}