import { Injectable } from "@angular/core"

import { PaymentPromotionDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"

@Injectable()
export class PaymentPromotionService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PaymentPromotionDto>>('/v1/admin/payment-promotion', pagingDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<PaymentPromotionDto>(`/v1/admin/payment-promotion/${id}`)
  }

  async update(id: string, paymentPromotionDto: PaymentPromotionDto) {
    return await this.httpService.put<PaymentPromotionDto>(`/v1/admin/payment-promotion/${id}`, paymentPromotionDto)
  }

  async create(paymentPromotionDto: PaymentPromotionDto) {
    return await this.httpService.post<PaymentPromotionDto>(`/v1/admin/payment-promotion`, paymentPromotionDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/payment-promotion/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/payment-promotion/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/payment-promotion/${id}/disable`)
  }
}