import * as _ from 'lodash'

import { BaseDto } from '../common/base.dto'
import { PhFileDto } from '../common/ph-file.dto'
import { EventTypeDto } from '../event-type/event-type.dto'
import { PagingDto } from '../paging.dto'
import { UserPropertyDto } from '../user/user-property.dto'

export class EventDto extends BaseDto {
  requestNo: string
  requestDate: string
  customerId: string
  fullName: string
  phoneNumber: string
  location: string
  description: string
  startDate: string
  endDate: string
  status: string
  attachment: PhFileDto
  eventType: EventTypeDto
  paymentRequest: PaymentRequestDto
  ownedProperty: UserPropertyDto

  static create() {
    const result = new EventDto()
    return result
  }
}

export class EventRatingDto extends BaseDto {
  rate: number
  description: string

  static create() {
    return new EventRatingDto()
  }
}

export class FindEventDto extends PagingDto {
  public status: string
  public searchText: string
}

export class PaymentRequestDto extends BaseDto {
  amount: number
  paymentAttachmentUrl: string
  khqrUrl: string
  submitPaymentUrl: string

  static create() {
    return new PaymentRequestDto()
  }
}  
