import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FeaturedProjectDto, PageResultDto, ProjectDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { ProjectForm } from '../../+admin/+project/edit-project/edit-project.utils'


@Injectable()
export class ProjectService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createProject: ProjectForm) {
    return await this.httpService.post<ProjectDto>(`/v1/admin/projects`, createProject)
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ProjectDto>>('/v1/admin/projects', pagingDto)
  }

  async findOne(projectId: string) {
    return this.httpService.get<ProjectDto>(`/v1/admin/projects/${projectId}`)
  }

  async createFeature({ projectId }: FeaturedProjectDto) {
    return await this.httpService.post<Boolean>('/v1/admin/projects/featured', { projectId })
  }

  async findFeature() {
    return await this.httpService.get<ProjectDto[]>('/v1/admin/projects/featured')
  }

  async sortOrder(projectIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/projects/reorder`, { projectIds })
  }

  async deleteFeature(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/projects/featured/${id}`)
  }

  async update(projectId: string, updateProject: ProjectForm) {
    return await this.httpService.put<boolean>(`/v1/admin/projects/${projectId}`, updateProject)
  }

  async destroy(projectId: string) {
    return this.httpService.delete<ProjectDto>(`/v1/admin/projects/${projectId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/v1/admin/projects/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/v1/admin/projects/${id}/enable`)
    }
    
    return await this.httpService.put(`/v1/admin/projects/${id}/disable`)
  }
}
