import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'

export class HouseTypeDto extends BaseDto {
  houseTypeCode: string
  projectCode: string
  banner?: FileDto
  fileId: string
  enabled?: boolean

  static create(houseType?: HouseTypeDto) {
    const result = new HouseTypeDto()

    result.id = houseType.id
    result.houseTypeCode = houseType.houseTypeCode
    result.projectCode = houseType.projectCode
    result.banner = houseType?.banner ?? null
    result.fileId = houseType?.banner?.id ?? null
    result.enabled =  houseType?.enabled ?? true
    
    return result
  }
}