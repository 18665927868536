import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { UserDto } from '../user.dto'
import { PagingDto } from '../common/paging.dto'

export class PromotionDto {
  id:string
  title: TranslationDto
  content: TranslationDto
  thumbnail: FileDto
  thumbnailId: string
  banner: FileDto
  bannerId: string
  publishedAt: string
  enabled: boolean
  isNewBatch: boolean
  promotionType: string
  expiredAt: string
  isPublished: boolean
  updatedBy: UserDto

  static create() {
    const page = new PromotionDto()

    page.title = TranslationDto.create()
    page.updatedBy = UserDto.create()
    page.content = TranslationDto.create()
    page.isPublished = false
    page.enabled = true
    
    return page
  }
}

export class findPromotionDto extends PagingDto {
  isPublished?: boolean
}

export class FeaturedPromotionDto {
  promotionId: string
}
