import * as _ from 'lodash'
import { TranslationDto } from '../common/translation.dto'
import { FileDto } from '../common/file.dto'

export class PageDto {
  id:string
  title: TranslationDto
  content: TranslationDto
  banner: FileDto
  bannerId: string
  type: string

  static create() {
    const page = new PageDto()

    page.title = TranslationDto.create()
    page.content = TranslationDto.create()
    
    return page
  }
}
