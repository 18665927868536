import { Injectable } from "@angular/core"

import { PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"
import { FindPaymentDto, PaymentDto } from "../dto/payment/payment.dto"


@Injectable()
export class PaymentService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findPaymentDto: FindPaymentDto) {
    return await this.httpService.get<PageResultDto<PaymentDto>>('/v1/admin/payment', findPaymentDto)
  }
}