import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'

export class AllowPhoneNumberDto extends BaseDto {
  otpCode: string
  phoneNumber?: string

  static create(location?: AllowPhoneNumberDto) {
    const result = new AllowPhoneNumberDto()

    result.id = location?.id ?? null,
    result.otpCode = location?.otpCode ?? null,
    result.phoneNumber =  location?.phoneNumber ?? null
    
    return result
  }
}