import { Injectable } from "@angular/core"

import { EmergencyDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"

@Injectable()
export class EmergencyService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<EmergencyDto>>('/v1/admin/emergency', pagingDto)
  }

  async update(id: string, emergencyDto: EmergencyDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/emergency/${id}`, emergencyDto)
  }

  async create(emergencyDto: EmergencyDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/emergency`, emergencyDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/emergency/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/emergency/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/emergency/${id}/disable`)
  }

  async sortOrder(categoryIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/emergency/reorder`, { categoryIds })
  }
}