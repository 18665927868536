import * as _ from 'lodash'

import { BaseDto } from '../common/base.dto'
import { TranslationDto } from '../common/translation.dto'
import { EmergencyCategoryDto } from '../emergency-category/emergency-category.dto'

export class EmergencyDto extends BaseDto {
  title: TranslationDto
  description: TranslationDto
  latitude: number
  longitude: number
  phoneNumber: string
  secondaryPhoneNumber: string
  tertiaryPhoneNumber: string
  shareUrl: string
  category?: EmergencyCategoryDto
  emergencyCategoryId: string

  static create(emergency?: EmergencyDto) {
    const result = new EmergencyDto()

    result.id = emergency?.id ?? null
    result.title = emergency?.title ?? TranslationDto.create()
    result.description = emergency?.description ?? TranslationDto.create()
    result.category = emergency?.category ?? EmergencyCategoryDto.create()
    result.phoneNumber = emergency?.phoneNumber ?? null
    result.secondaryPhoneNumber = emergency?.secondaryPhoneNumber ?? null
    result.tertiaryPhoneNumber = emergency?.tertiaryPhoneNumber ?? null
    result.shareUrl = emergency?.shareUrl ?? null
    result.emergencyCategoryId = emergency?.category?.id ?? null
    result.latitude = emergency?.latitude ?? null
    result.longitude = emergency?.longitude ?? null

    return result
  }
}