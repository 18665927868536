import { NgModule } from '@angular/core'
import { ProfilePlaceholderPipe } from './pipes'

@NgModule({
  declarations: [
    ProfilePlaceholderPipe
  ],
  exports: [
    ProfilePlaceholderPipe
  ]
})
export class PlaceholderModule {
	
}
