import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { TranslationDto } from '../common/translation.dto'

export class PropertyTypeDto extends BaseDto {
  title: TranslationDto
  enabled?: boolean

  static create(category?: PropertyTypeDto) {
    const result = new PropertyTypeDto()

    result.id = category?.id ?? null,
    result.title = category?.title ?? TranslationDto.create()
    result.enabled =  category?.enabled ?? true
    
    return result
  }
}