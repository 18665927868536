import { Injectable } from "@angular/core"

import { PropertyTypeDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"


@Injectable()
export class PropertyTypeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PropertyTypeDto>>('/v1/admin/property-types', pagingDto)
  }

  async update(id: string, updatePropertyTypeOnboardDto: PropertyTypeDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/property-types/${id}`, updatePropertyTypeOnboardDto)
  }

  async create(createPropertyTypeOnboardDto: PropertyTypeDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/property-types`, createPropertyTypeOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/property-types/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/property-types/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/property-types/${id}/disable`)
  }
}