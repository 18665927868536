import * as _ from 'lodash'

import { PagingDto } from "./paging.dto"
import { TranslationDto } from './common/translation.dto'

export class FindPushNotificationDto extends PagingDto {
}

export class CreatePushNotificationDto {
  title?: TranslationDto
  message?: TranslationDto
  creator?: string
  link?: string

  static create({ title, message, creator, link }: CreatePushNotificationDto) {
    return _.assign(new PushNotificationDto(), {
      title: title || TranslationDto.create(),
      message: message || TranslationDto.create(),
      creator,
      link
    })
  }
}

export class PushNotificationDto {
  title: TranslationDto
  message: TranslationDto
  link: string

  static create({ title, message }: PushNotificationDto) {
    return _.assign(new PushNotificationDto(), {
      title: title || TranslationDto.create(),
      message: message || TranslationDto.create()
    })
  }
}