import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { AwardDto, PageDto, PageResultDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { FindAwardDto } from '../dto/award/find-awrd.dto'

@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/v1/admin/pages', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<PageDto>(`/v1/admin/pages/${pageId}`)
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/v1/admin/pages/${pageId}`, updatePageDto)
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Award
  ////////////////////////////////////////////////////////////////////////////////////////////////

  async findAward(pageId: string, findAwardDto: FindAwardDto) {
    return await this.httpService.get<PageResultDto<AwardDto>>(`/v1/admin/pages/${pageId}/award`, findAwardDto)
  }

  async updateAward(pageId: string, id: string, awardDto: AwardDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/pages/${pageId}/award/${id}`, awardDto)
  }

  async createAward(pageId: string, awardDto: AwardDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/pages/${pageId}/award`, awardDto)
  }

  async deleteAward(pageId: string, id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/pages/${pageId}/award/${id}`)
  }

  async setEnabledAward(pageId: string, id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/pages/${pageId}/award/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/pages/${pageId}/award/${id}/disable`)
  }
}
