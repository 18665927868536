import { Injectable } from "@angular/core"

import { BookletDto, CreateBookletDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"
import { FindBookletDto } from "../dto/booklet/booklet.dto"


@Injectable()
export class BookletService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findBookletDto: FindBookletDto) {
    return await this.httpService.get<PageResultDto<BookletDto>>('/v1/admin/booklets', findBookletDto)
  }

  async update(id: string, updateBookletOnboardDto: CreateBookletDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/booklets/${id}`, updateBookletOnboardDto)
  }

  async create(createBookletOnboardDto: CreateBookletDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/booklets`, createBookletOnboardDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/booklets/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/booklets/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/booklets/${id}/disable`)
  }

  async sortOrderBooklet(bookletIds: string[], type: string) {
    return await this.httpService.put<Boolean>(`/v1/admin/booklets/reorder`, { bookletIds, type })
  }
}