import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'

export class UserDto extends BaseDto {
  fullName: string
  email: string
  photo: FileDto
  roleNames: string[]
  phoneNumber: string
  customerId: string
  enabled: boolean
  cardNumber: string
  appSigned: boolean
  secondaryPhoneNumber: string
  dob: string
  idCard: string
  address: string
  occupation: string
  facebookLink: string
  instagramLink: string
  twitterLink: string
  registeredAt: string
  remark: string
  underOwner: UserDto
  cardType: string

  static create(user?: UserDto) {
    const result = new UserDto()
    
    result.id = user?.id ?? null
    result.appSigned = user?.appSigned ?? false,
    result.fullName = user?.fullName ?? null
    result.email = user?.email ?? null
    result.photo = user?.photo ?? FileDto.create()
    result.phoneNumber = user?.phoneNumber ?? null
    result.customerId =  user?.customerId ?? null
    result.cardNumber =  user?.cardNumber ?? null
    result.secondaryPhoneNumber = user?.secondaryPhoneNumber ?? null
    result.registeredAt = user?.registeredAt ?? null
    result.dob = user?.dob ?? null
    result.idCard = user?.idCard ?? null
    result.address = user?.address ?? null
    result.occupation = user?.occupation ?? null
    result.underOwner = user?.underOwner ?? null
    result.facebookLink = user?.facebookLink ?? null
    result.instagramLink = user?.instagramLink ?? null
    result.twitterLink = user?.twitterLink ?? null
    result.remark = user?.remark ?? null
    result.cardType = user?.cardType ?? null

    return result
  }
}
export class CreateUserDto {
  fullName?: string
  email?: string
  photoId?: string
  roleName: string
  phoneNumber?: string
  password: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export class EPrivilegeCreateUserDto {
  fullName: string
  phoneNumber: string
  cardNumber: string
  remark: string
  underOwnerId?: string
}

export class SubordinateCreateUserDto {
  fullName: string
  phoneNumber: string
  remark: string
  underOwnerId: string
}

export class UpdateUserDto {
  fullName?: string
  email?: string
  photoId?: string
  roleName?: string
  password?: string
  phoneNumber?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  PH_USER = 'PH_USER',
  E_PRIVILEGE = 'E_PRIVILEGE',
  GUEST = 'GUEST',
  RESEARCH_DEVELOPMENT = 'RESEARCH_DEVELOPMENT',
  MARKETING = 'MARKETING',
  SALE = 'SALE',
  SERVICE = 'SERVICE',
  CALL_CENTER = 'CALL_CENTER',
  FACILITY_MANAGEMENT = 'FACILITY_MANAGEMENT',
  MKT_INVESTMENT = 'MKT_INVESTMENT',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT'
}

export function FindRoleNames(roleName: string) {
  if (roleName == 'admin') {
    return RoleNames.ADMIN
  } else if (roleName == 'research-development') {
    return RoleNames.RESEARCH_DEVELOPMENT
  } else if (roleName == 'marketing') {
    return RoleNames.MARKETING
  } else if (roleName == 'sale') {
    return RoleNames.SALE
  } else if (roleName == 'service') {
    return RoleNames.SERVICE
  } else if (roleName == 'call-center') {
    return RoleNames.CALL_CENTER
  } else if (roleName == 'facility-management') {
    return RoleNames.FACILITY_MANAGEMENT
  } else if (roleName == 'mkt-investment') {
    return RoleNames.MKT_INVESTMENT
  } else if (roleName == 'business-development') {
    return RoleNames.BUSINESS_DEVELOPMENT
  }
}

export enum UserStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending'
}

export class AuthDto extends BaseDto {
  user: UserDto
  sessionToken: string

  static create() {
    return new UserDto()
  }
}