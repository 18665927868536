import * as _ from 'lodash'
import { TranslationDto } from '../common/translation.dto'
import { BaseDto } from '../base.dto'
import { FileDto } from '../file.dto'

export class CardInformationDto extends BaseDto {
  title: TranslationDto
  content: TranslationDto
  imageId: string
  image: FileDto
  cardType: string

  static create() {
    const card = new CardInformationDto()

    card.title = TranslationDto.create()
    card.content = TranslationDto.create()
    
    return card
  }
}
