import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { FeaturedPromotionDto, PageResultDto, PromotionDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { findPromotionDto } from '../dto/promotion/promotion.dto'


@Injectable()
export class PromotionService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createPromotion: PromotionDto) {
    return await this.httpService.post<PromotionDto>(`/v1/admin/promotions`, createPromotion)
  }

  async find(pagingDto: findPromotionDto) {
    return await this.httpService.get<PageResultDto<PromotionDto>>('/v1/admin/promotions', pagingDto)
  }

  async findOne(promotionId: string) {
    return this.httpService.get<PromotionDto>(`/v1/admin/promotions/${promotionId}`)
  }

  async createFeature({ promotionId }: FeaturedPromotionDto) {
    return await this.httpService.post<Boolean>('/v1/admin/promotions/featured', { promotionId })
  }

  async findFeature() {
    return await this.httpService.get<PromotionDto[]>('/v1/admin/promotions/featured')
  }

  async sortOrder(promotionIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/promotions/featured/reorder`, { promotionIds })
  }

  async deleteFeature(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/promotions/featured/${id}`)
  }

  async update(promotionId: string, updatePromotion: PromotionDto) {
    return await this.httpService.put<boolean>(`/v1/admin/promotions/${promotionId}`, updatePromotion)
  }

  async destroy(promotionId: string) {
    return this.httpService.delete<PromotionDto>(`/v1/admin/promotions/${promotionId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/v1/admin/promotions/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/v1/admin/promotions/${id}/enable`)
    }
    
    return await this.httpService.put(`/v1/admin/promotions/${id}/disable`)
  }
}
