import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { CategoryDto } from '../category/category.dto'
import { CardInformationDto } from '../card-information/card-information.dto'
import { UserDto } from '../user.dto'

export class MerchantDto extends BaseDto {
  logo?: FileDto
  banner?: FileDto
  logoId?: string
  bannerId?: string
  title: TranslationDto
  category: CategoryDto
  categoryId: string
  isNewBatch?: boolean
  updatedBy: UserDto
  enabled: boolean

  static create(merchant?: MerchantDto) {
    const result = new MerchantDto()

    result.id = merchant?.id ?? null
    result.title = merchant?.title ?? TranslationDto.create()
    result.logo = merchant?.logo ?? null
    result.banner = merchant?.banner ?? null
    result.logoId = merchant?.logo?.id ?? null
    result.category = merchant?.category ?? null
    result.categoryId = merchant?.category?.id ?? null
    result.bannerId = merchant?.banner?.id ?? null
    result.isNewBatch = merchant?.isNewBatch ?? true
    result.updatedBy = merchant?.updatedBy ?? UserDto.create()
    result.enabled = merchant?.enabled ?? null

    return result
  }
}

export class FeaturedMerchantDto {
  merchantId: string
}

export class DiscountMerchantDto extends BaseDto {
  discount: TranslationDto
  merchant: MerchantDto
  merchantId: string
  cardInformationId: string
  cardInformation: CardInformationDto

  static create(discountMerchant?: DiscountMerchantDto) {
    const result = new DiscountMerchantDto()

    result.id = discountMerchant?.id ?? null
    result.discount = discountMerchant?.discount ?? TranslationDto.create()
    result.merchant = discountMerchant?.merchant ?? null
    result.merchantId = discountMerchant?.merchant?.id ?? null
    result.cardInformation = discountMerchant?.cardInformation ?? null
    result.cardInformationId = discountMerchant?.cardInformation?.id ?? null
    result.createdAt = discountMerchant?.createdAt ?? null
    result.updatedAt = discountMerchant?.updatedAt ?? null

    return result
  }
}


