import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class RepairTypeDto extends BaseDto {
  title: TranslationDto = TranslationDto.create()
  description: TranslationDto = TranslationDto.create()
  telegramChatId: string
  icon?: FileDto
  fileId: string
  enabled?: boolean

  static create(repairType?: RepairTypeDto) {
    const result = new RepairTypeDto()

    result.id = repairType.id
    result.title = repairType.title
    result.description = repairType.description
    result.telegramChatId = repairType.telegramChatId ?? null,
    result.icon = repairType?.icon ?? null
    result.fileId = repairType?.icon?.id ?? null
    result.enabled =  repairType?.enabled ?? true
    
    return result
  }
}