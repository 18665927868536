import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import * as _ from 'lodash'

import { NbMenuService, NbMenuItem } from '@nebular/theme'

import { AuthService } from '@api/services'
import { BaseError } from '@api/errors'

import { LAYOUT_MODULE_CONFIG, MenuItem } from './layout.constants'
import { MMSLayoutService } from './layout.service'
import { LayoutModuleConfig } from './layout.module'
import { AuthStore } from '../../@api/store/auth'

@Component({
  selector: 'layout',
  styleUrls: ['layout.component.scss'],
  templateUrl: 'layout.component.html',
})
export class LayoutComponent implements OnInit {
  public menu: MenuItem[]
  public error: BaseError
  public mode: string
  public permissions: string[]
  public currentRole: any[]

  constructor(
    @Inject(LAYOUT_MODULE_CONFIG)
    private config: LayoutModuleConfig,
    private layoutService: MMSLayoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private menuService: NbMenuService,
    private authStore: AuthStore,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService) {
  }

  public ngOnInit() {
    this.menu = _.cloneDeep(this.config.menu)
    this.currentRole = this.authStore['storeValue']?.user?.roleNames || []
    this.menu = this.menu.filter(o => {
      if (_.intersection(o?.roles, this.currentRole)?.length) {
        o.children = o.children ? o.children.filter(c => _.intersection(c?.roles, this.currentRole)?.length) : null
        return o
      }
    })

    this.menuService.onItemClick().subscribe(async(value) => {
      const tag = value.item['tag']

      switch (tag) {
        case 'logout': {
          try {
            try {
              await this.authService.logOut()
            } catch (error) {
              
            }

            this.router.navigate([`${this.config.root}/login`], {relativeTo: this.activatedRoute})
          } catch (error) {
            this.error = error
          }
        }
          break;
        case 'profile': {
          this.router.navigate([`${this.config.root}/profile`], {relativeTo: this.activatedRoute})
        }
          break;
        default:
          break;
      }
    })

    this.layoutService.onModeChange.subscribe(mode => {
      this.mode = mode
      this.changeDetectorRef.detectChanges()
    })
  }
}
