import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class EventTypeDto extends BaseDto {
  title: TranslationDto = TranslationDto.create()
  description: TranslationDto = TranslationDto.create()
  telegramChatId: string
  icon?: FileDto
  fileId: string
  enabled?: boolean

  static create(eventType?: EventTypeDto) {
    const result = new EventTypeDto()

    result.id = eventType.id
    result.title = eventType.title
    result.description = eventType.description
    result.telegramChatId = eventType.telegramChatId ?? null,
    result.icon = eventType?.icon ?? null
    result.fileId = eventType?.icon?.id ?? null
    result.enabled =  eventType?.enabled ?? true
    
    return result
  }
}