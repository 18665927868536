import * as _ from 'lodash'

import { BaseDto } from '../common/base.dto'
import { UserPropertyDto } from '../user/user-property.dto'
import { RepairTypeDto } from '../repair-type/repair-type.dto'
import { PhFileDto } from '../common/ph-file.dto'

export class DefectDto extends BaseDto {
  customerId: string
  status: string
  requestDate: string
  reason: string
  rating: DefectRatingDto
  ownedProperty: UserPropertyDto
  appointment: DefectAppointmentDto
  reAppointment: DefectAppointmentDto
  repairItems: DefectRepairItemsDto[]

  static create() {
    const result = new DefectDto()
    return result
  }
}

export class DefectRepairItemsDto extends BaseDto {
  video: PhFileDto
  description: string
  voice: PhFileDto
  repairType: RepairTypeDto
  imageUrls: string[]

  static create() {
    return new DefectRepairItemsDto()
  }
}

export class DefectAppointmentDto extends BaseDto {
  appointmentDate: string
  appointmentTime: string
  description: string
  estimatedCompletionDate: string
  startDate: string

  static create() {
    return new DefectAppointmentDto()
  }
}

export class DefectRatingDto extends BaseDto {
  rate: number
  description: string

  static create() {
    return new DefectRatingDto()
  }
}
