import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'


export class BannerDto extends BaseDto {
  image?: FileDto
  enabled?: boolean

  static create(banner?: BannerDto) {
    const result = new BannerDto()

    result.image = banner?.image ?? null
    result.enabled =  banner?.enabled ?? true
    result.id = banner?.id ?? null
    
    return result
  }
}

export class CreateBannerDto {
  imageId?: string
  title?: TranslationDto
  content?: TranslationDto
}