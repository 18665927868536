import { BaseDto } from "../base.dto"
import { FileDto } from "../file.dto"
import { UserDto } from "./user.dto"

export class UserRequestDto extends BaseDto {
  text: string
  status: string
  requestType: string
  owner: UserDto
  file: FileDto

  static create(data: UserRequestDto) {
    return Object.assign(new UserRequestDto(), data)
  }
}