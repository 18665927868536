import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { CorporateBankSocialDto } from './corporate-bank-social'

export class CorporateBankDto extends BaseDto {
  name: _.Dictionary<string>
  description: _.Dictionary<string>
  location: _.Dictionary<string>
  banner: FileDto
  bannerId: string
  email: string
  enabled: boolean
  sortOrder: number
  phoneNumber: string
  secondaryPhoneNumber: string
  longitude: number
  latitude: number
  website: string
  socials: CorporateBankSocialDto[]

  static create(bank?: CorporateBankDto) {
    const result = new CorporateBankDto()

    result.id = result.id ?? null
    result.name = bank?.name ?? TranslationDto.create()
    result.description = bank?.description ?? TranslationDto.create()
    result.location = bank?.location ?? TranslationDto.create()
    result.banner = bank?.banner ?? null
    result.bannerId = bank?.bannerId ?? null
    result.phoneNumber = bank?.phoneNumber ?? null
    result.secondaryPhoneNumber = bank?.secondaryPhoneNumber ?? null
    result.longitude =  bank?.longitude ?? null
    result.latitude =  bank?.latitude ?? null
    result.website =  bank?.website ?? null
    result.longitude =  bank?.longitude ?? null
    result.socials = bank?.socials ?? []
    
    return result
  }
}