<div class="header-container">
  <div class="logo-container">
    <a *ngIf="mode == 'COLUMN_MODE'" (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a *ngIf="mode == 'FULL_MODE'" class="sidebar-toggle cursor-pointer" (click)="onBack()">
      <nb-icon icon="arrow-ios-back-outline"></nb-icon>
    </a>
    <a class="logo">
      <img src="./assets/images/logo.png" height="30"/>
    </a>
    <span class="title-hr">Peng Huoth</span>
    <p class="role"></p>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user 
        [nbContextMenu]="items"
        [nbContextMenuTag]="profile"
        [onlyPicture]="userPictureOnly"
        [name]="(authUser | async)?.fullName"
        [picture]="(authUser | async)?.photo?.url | profilePlaceholder">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
