import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { MerchantDto } from '../merchant/merchant.dto'
import { UserDto } from '../user.dto'

export class StoreDto extends BaseDto {
  logo?: FileDto
  banner?: FileDto
  logoId?: string
  bannerId?: string
  title: _.Dictionary<string>
  description: _.Dictionary<string>
  address: _.Dictionary<string>
  merchant: MerchantDto
  merchantId: string
  code: string
  openHour: string
  closeHour: string
  longitude: number
  latitude: number
  enabled: boolean
  phoneNumber: string
  secondaryPhoneNumber: string
  updatedBy: UserDto

  static create(store?: StoreDto) {
    const result = new StoreDto()

    result.id = store?.id ?? null,
    result.title = store?.title ?? TranslationDto.create()
    result.logo = store?.logo ?? null
    result.banner = store?.banner ?? null
    result.bannerId = store?.banner?.id ?? null
    result.logoId = store?.logo?.id ?? null
    result.description = store?.description ?? TranslationDto.create()
    result.address = store?.address ?? TranslationDto.create()
    result.merchant = store?.merchant ?? null
    result.merchantId = store?.merchant?.id ?? null
    result.code = store?.code ?? null
    result.openHour = store?.openHour ?? null
    result.enabled = store?.enabled ?? true
    result.closeHour = store?.closeHour ?? null
    result.longitude = store?.longitude ?? null
    result.latitude = store?.latitude ?? null
    result.updatedBy = store?.updatedBy ?? UserDto.create()
    result.phoneNumber = store?.phoneNumber ?? null
    result.secondaryPhoneNumber = store?.secondaryPhoneNumber ?? null

    return result
  }
}
