import { Injectable } from "@angular/core"

import { PriceRangeDto } from "../dto"
import { HttpService } from "./http.service"


@Injectable()
export class PriceRangeService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find() {
    return await this.httpService.get<PriceRangeDto[]>('/v1/admin/price-ranges')
  }

  async update(id: string, priceRangeDto: PriceRangeDto) {
    return await this.httpService.put<Boolean>(`/v1/admin/price-ranges/${id}`, priceRangeDto)
  }

  async create(priceRangeDto: PriceRangeDto) {
    return await this.httpService.post<Boolean>(`/v1/admin/price-ranges`, priceRangeDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/v1/admin/price-ranges/${id}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.patch<Boolean>(`/v1/admin/price-ranges/${id}/enable`)
    }

    return await this.httpService.patch<Boolean>(`/v1/admin/price-ranges/${id}/disable`)
  }

  async sortOrder(priceRangeIds: string[]) {
    return await this.httpService.put<Boolean>(`/v1/admin/price-ranges/reorder`, { priceRangeIds })
  }
}