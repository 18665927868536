import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class AmenityDto extends BaseDto {
  logo?: FileDto
  banner?: FileDto
  title: TranslationDto
  enabled?: boolean

  static create(category?: AmenityDto) {
    const result = new AmenityDto()

    result.id = category?.id ?? null,
    result.title = category?.title ?? TranslationDto.create()
    result.logo = category?.logo ?? null
    result.banner = category?.banner ?? null
    result.enabled =  category?.enabled ?? true
    
    return result
  }
}

export class CreateAmenityDto {
  logoId?: string
  bannerId?: string
  title?: TranslationDto
}