import { PagingDto } from '../common/paging.dto'

export class FindUserDto extends PagingDto {
  public underOwnerId?: string
  public searchText: string
  public roleName?: string
  public status?: string
  public appSigned?: boolean
}

export class FindUserRequestDto extends PagingDto {
  public searchText?: string
  public status: string
}


