import { Injectable } from "@angular/core"

import { DateFilterDto, EventDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { FindEventDto } from "../dto/event/event.dto"
import { ExportExcelResultDto } from "@api/dto/common/export-excel-result.dto"

@Injectable()
export class EventService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findEventDto: FindEventDto) {
    return await this.httpService.get<PageResultDto<EventDto>>('/v1/admin/events', findEventDto)
  }

  async findOne(id: string) {
    return await this.httpService.get<EventDto>(`/v1/admin/events/${id}`)
  }

  async exportExcel(dateFilterDto: DateFilterDto, status?: string) {
    let params = { ...dateFilterDto };
    if (status) {
      params['status'] = status;
    }

    return this.httpService.get<ExportExcelResultDto>(`/v1/admin/events/export`, params)
  }
}