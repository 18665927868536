import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, CreateUserDto, UpdateUserDto, UserRequestDto, UserPropertyDto, DateFilterDto } from '../dto'

import { HttpService } from './http.service'
import { FindUserRequestDto } from '../dto/user/find-user.dto'
import { EPrivilegeCreateUserDto, SubordinateCreateUserDto } from '../dto/user/user.dto'
import { ExportExcelResultDto } from '../dto/common/export-excel-result.dto'

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createUserDto: CreateUserDto) {
    return this.httpService.post<UserDto>('/v1/admin/users/', createUserDto)
  }

  async getEPrivilege(id: string) {
    return this.httpService.get<UserDto[]>(`/v1/admin/users/${id}/e-privilege`)
  }

  async getAdminUser(id: string) {
    return this.httpService.get<UserDto[]>(`/v1/admin/users/${id}/admin-user`)
  }

  async createEPrivilege(ePrivilegeCreateUserDto: EPrivilegeCreateUserDto) {
    return this.httpService.post<UserDto>('/v1/admin/users/e-privilege', ePrivilegeCreateUserDto)
  }

  async createSubordinate(subordinateCreateUserDto: SubordinateCreateUserDto) {
    return this.httpService.post<UserDto>('/v1/admin/users/subordinate', subordinateCreateUserDto)
  }
  
  async createAdminUser(adminCreateUserDto: CreateUserDto) {
    return this.httpService.post<UserDto>('/v1/admin/users/admin-user', adminCreateUserDto)
  }
  
  async updateEPrivilege(id: string, ePrivilegeCreateUserDto: EPrivilegeCreateUserDto) {
    return this.httpService.put<UserDto[]>(`/v1/admin/users/${id}/e-privilege`, ePrivilegeCreateUserDto)
  }

  async updateSubordinate(id: string, subordinateCreateUserDto: SubordinateCreateUserDto) {
    return this.httpService.put<UserDto[]>(`/v1/admin/users/${id}/subordinate`, subordinateCreateUserDto)
  }

  async updateAdminUser(id: string, adminUpdateUserDto: UpdateUserDto) {
    return this.httpService.put<UserDto[]>(`/v1/admin/users/${id}/admin-user`, adminUpdateUserDto)
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/v1/admin/users', findUserDto)
  }

  
  async findProperty(id: string, findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserPropertyDto>>(`/v1/admin/users/${id}/properties`, findUserDto)
  }

  async findDetailProperty(id: string, propertyId: string) {
    return await this.httpService.get<UserPropertyDto>(`/v1/admin/users/${id}/properties/${propertyId}`)
  }

  async findRequest(findUserRequestDto: FindUserRequestDto) {
    return await this.httpService.get<PageResultDto<UserRequestDto>>('/v1/admin/users/request', findUserRequestDto)
  }

  async setVerify(id: string) {
    return this.httpService.put<UserDto>(`/v1/admin/users/request/${id}`)
  }

  async exportExcel(dateFilterDto: DateFilterDto) {
    return this.httpService.get<ExportExcelResultDto>(`/v1/admin/users/export`, dateFilterDto)
  }

  async findOne(id: string) {
    return this.httpService.get<UserDto>(`/v1/admin/users/${id}`)
  }
  
  async update(id: string, updateUserDto: UpdateUserDto) {
    return this.httpService.put<UserDto>(`/v1/admin/users/${id}`, updateUserDto)
  }

  async updateAdmin(id: string, updateUserDto: UpdateUserDto) {
    return this.httpService.put<UserDto>(`/v1/admin/users/${id}`, updateUserDto)
  }

  async destroy(id: string) {
    return this.httpService.delete<boolean>(`/v1/admin/users/${id}`)
  }

  async destroyAdmin(id: string) {
    return this.httpService.delete<boolean>(`/v1/admin/users/${id}/admin-user`)
  }

  async updatePassword(id: string, password: string) {
    return this.httpService.post<UserDto>(`/v1/admin/users/${id}/password`, {password})
  }

  async resetUser(phoneNumber: string) {
    return this.httpService.post<UserDto[]>(`/v1/mock/user/reset/data`, { phoneNumber } )
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/v1/admin/users/${id}/enable`)
    }
    
    return await this.httpService.put(`/v1/admin/users/${id}/disable`)
  }
}
