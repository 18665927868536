import { Injectable } from "@angular/core"

import { DateFilterDto, DefectDto, PageResultDto } from "../dto"
import { HttpService } from "./http.service"
import { PagingDto } from "../dto/paging.dto"
import { ExportExcelResultDto } from "@api/dto/common/export-excel-result.dto"
import { FindDefectDto } from "@api/dto/defect/find-defect.dto"

@Injectable()
export class DefectService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findDefect: FindDefectDto) {
    return await this.httpService.get<PageResultDto<DefectDto>>('/v1/admin/defects', findDefect)
  }
  

  async findOne(id: string) {
    return await this.httpService.get<DefectDto>(`/v1/admin/defects/${id}`)
  }

  async exportExcel(dateFilterDto: DateFilterDto, status?: string) {
    let params = { ...dateFilterDto };
    if (status) {
      params['status'] = status;
    }

    return this.httpService.get<ExportExcelResultDto>(`/v1/admin/defects/export`, params)
  }
}